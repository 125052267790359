.hint-block {
  &__pointer {
    cursor: pointer;
  }
  &__preview {
    opacity: 0;
    position: absolute;
    right: 40px;
    top: -5200px;
    transition: opacity 0.1s;
    width: 185px;
    z-index: -1;
  }
  &__pointer:hover + &__preview {
    opacity: 1;
    top: -180px;
    transition: opacity 0.3s;
    z-index: 3;
  }
}
