@import '../../../../../style/color';

.btn-label {
  display: block;
  margin-bottom: 5px;
}

.upload-button-preview {
  width: 250px;
  margin: 30px 0;
}

.option-label {
  display: block;
  line-height: normal;
  margin-top: 5px;
  color: $second__color_dark;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  .button {
    background-color: white;
    &:hover {
      transition: 0.3s;
      background-color: $field-color-ligth;
    }
    &:disabled {
      background-color: $second__color;
      color: $second__color_dark;
      cursor: not-allowed;
      border-color: transparent;
      .attach-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9728 0.437577C11.4421 0.0761672 10.7823 -0.0699307 10.1384 0.0314106C9.4945 0.132752 8.91927 0.473235 8.53921 0.977985L2.33006 9.22456C2.18877 9.40265 2.08638 9.60583 2.0289 9.82214C1.97143 10.0385 1.96005 10.2635 1.99542 10.4841C2.0308 10.7046 2.11222 10.9162 2.23488 11.1064C2.35754 11.2966 2.51897 11.4615 2.70966 11.5914C2.90034 11.7213 3.11642 11.8135 3.34517 11.8627C3.57392 11.912 3.81071 11.9171 4.04159 11.8779C4.27247 11.8388 4.49278 11.756 4.68952 11.6346C4.88626 11.5131 5.05547 11.3554 5.18717 11.1708L9.57126 5.34874L8.9997 4.95882L4.61631 10.7822C4.45333 10.9982 4.20702 11.1439 3.93135 11.1874C3.65567 11.2309 3.37311 11.1687 3.14557 11.0143C2.9184 10.8591 2.76527 10.6244 2.7198 10.3618C2.67433 10.0992 2.74022 9.83022 2.90303 9.61382L9.11077 1.36724H9.11218L9.11077 1.3659C9.38301 1.00599 9.79406 0.76337 10.2539 0.691166C10.7138 0.618961 11.185 0.723057 11.5643 0.98066C11.9427 1.23965 12.1977 1.6307 12.2736 2.06819C12.3495 2.50567 12.2401 2.95395 11.9693 3.31485V3.31351L5.16397 12.3533C4.78353 12.8575 4.20848 13.1977 3.56486 13.2993C2.92123 13.4008 2.26154 13.2555 1.73038 12.8951C1.20045 12.5327 0.843151 11.9852 0.736803 11.3727C0.630454 10.7601 0.783729 10.1324 1.16303 9.62719L6.10884 3.05802L5.53728 2.66877L0.59147 9.23794C0.34954 9.55921 0.176534 9.92268 0.0823421 10.3076C-0.0118499 10.6925 -0.0253817 11.0913 0.0425204 11.4812C0.110423 11.8711 0.258427 12.2444 0.478074 12.5799C0.697722 12.9153 0.984704 13.2063 1.32262 13.4361C1.66012 13.6665 2.04203 13.8314 2.44654 13.9212C2.85104 14.0111 3.2702 14.0242 3.68004 13.9599C4.08988 13.8955 4.48237 13.7549 4.83507 13.5462C5.18777 13.3374 5.49375 13.0646 5.73553 12.7432L12.5409 3.70344C12.9204 3.19846 13.0736 2.57077 12.9668 1.95835C12.86 1.34594 12.502 0.798931 11.9714 0.437577H11.9728Z' fill='grey'/%3E%3C/svg%3E%0A");
      }
    }
    transition: background-color 0.2s;
    cursor: pointer;
    padding: 10px 12px 8px 12px;
    border: 1px solid $first__color;
    border-radius: 8px;
    display: flex;
    align-items: center;
    .attach-icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      position: relative;
      bottom: 2px;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9728 0.437577C11.4421 0.0761672 10.7823 -0.0699307 10.1384 0.0314106C9.4945 0.132752 8.91927 0.473235 8.53921 0.977985L2.33006 9.22456C2.18877 9.40265 2.08638 9.60583 2.0289 9.82214C1.97143 10.0385 1.96005 10.2635 1.99542 10.4841C2.0308 10.7046 2.11222 10.9162 2.23488 11.1064C2.35754 11.2966 2.51897 11.4615 2.70966 11.5914C2.90034 11.7213 3.11642 11.8135 3.34517 11.8627C3.57392 11.912 3.81071 11.9171 4.04159 11.8779C4.27247 11.8388 4.49278 11.756 4.68952 11.6346C4.88626 11.5131 5.05547 11.3554 5.18717 11.1708L9.57126 5.34874L8.9997 4.95882L4.61631 10.7822C4.45333 10.9982 4.20702 11.1439 3.93135 11.1874C3.65567 11.2309 3.37311 11.1687 3.14557 11.0143C2.9184 10.8591 2.76527 10.6244 2.7198 10.3618C2.67433 10.0992 2.74022 9.83022 2.90303 9.61382L9.11077 1.36724H9.11218L9.11077 1.3659C9.38301 1.00599 9.79406 0.76337 10.2539 0.691166C10.7138 0.618961 11.185 0.723057 11.5643 0.98066C11.9427 1.23965 12.1977 1.6307 12.2736 2.06819C12.3495 2.50567 12.2401 2.95395 11.9693 3.31485V3.31351L5.16397 12.3533C4.78353 12.8575 4.20848 13.1977 3.56486 13.2993C2.92123 13.4008 2.26154 13.2555 1.73038 12.8951C1.20045 12.5327 0.843151 11.9852 0.736803 11.3727C0.630454 10.7601 0.783729 10.1324 1.16303 9.62719L6.10884 3.05802L5.53728 2.66877L0.59147 9.23794C0.34954 9.55921 0.176534 9.92268 0.0823421 10.3076C-0.0118499 10.6925 -0.0253817 11.0913 0.0425204 11.4812C0.110423 11.8711 0.258427 12.2444 0.478074 12.5799C0.697722 12.9153 0.984704 13.2063 1.32262 13.4361C1.66012 13.6665 2.04203 13.8314 2.44654 13.9212C2.85104 14.0111 3.2702 14.0242 3.68004 13.9599C4.08988 13.8955 4.48237 13.7549 4.83507 13.5462C5.18777 13.3374 5.49375 13.0646 5.73553 12.7432L12.5409 3.70344C12.9204 3.19846 13.0736 2.57077 12.9668 1.95835C12.86 1.34594 12.502 0.798931 11.9714 0.437577H11.9728Z' fill='black'/%3E%3C/svg%3E%0A");
    }
    .file-drop-target {
      height: 100%;
    }
  }
  .file-names {
    margin-left: 10px;
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.drag-area {
  border: 3px solid #9da6ba;
  border-radius: 8px;
  padding: 8% 7%;
  .drag-wrapper {
    padding: 20px 0;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // border: 1px dashed #9da6ba;
    border-radius: 8px;
    h3 {
      text-align: center;
      margin-bottom: 15px;
    }
    .drag-file-names {
      height: 15px;
    }
    .upload-rules {
      width: 200px;
      text-align: center;
      font-size: 12px;
      color: #9da6ba;
    }
  }
}

.file-drop-dragging-over-target {
  .drag-wrapper {
    background-color: #e8ecf3;
    transition: 0.3s;
    border: 1px dashed #068aa7;
  }
}

.preview-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  max-height: 65%;
  .reset-field {
    position: absolute;
    right: 10%;
    top: 5px;
    width: 10px;
    height: 10px;
  }
  .check-preview {
    border-radius: 10px;
    width: max-content;
    height: max-content;
    max-width: 85%;
    max-height: 120%;
  }
}

// #068aa7
