@import '../../../../style/breakpoints';

.newsContentCard {
  &.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    z-index: 2;

    @media screen and (min-width: $tablet) {
      display: block;
    }
  }

  &.tile {
    display: grid;
    width: 100%;
    margin: 0 auto;
    z-index: 2;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    justify-content: center;
    margin-bottom: 30px;
    @media screen and (min-width: $tablet) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: $desk_sm) {
      //justify-content: flex-start;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
