@import '../../../style/breakpoints';
@import '../../../style/popup/popup';
@import '../../../style/font-family';

@mixin icon {
  img {
    display: block;
    width: 40px;
    height: 40px;

    object-fit: contain;
    object-position: center;
  }
}

.popup {
  font-family: $primaryFont;
  min-width: 300px;
  width: 300px;
  overflow: auto;
  background-color: white;
  border-radius: 0.75rem;
  max-height: 97vh;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 3px;
  margin-bottom: 14vh;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media screen and (min-width: 360px) {
    width: 340px;
  }

  @media screen and (min-width: 400px) {
    width: 390px;
  }

  @media screen and (min-width: 500px) {
    width: 420px;
  }

  @media screen and (min-width: $desk_sm) {
    margin-bottom: 5vh;
    width: 450px;
  }

  .simplebar-content-wrapper {
    scroll-behavior: smooth;
    overflow: auto !important;
    overflow-x: hidden;
    overflow-y: scroll !important;
    height: 100% !important;
    outline: none !important;
  }
  .simplebar-scrollbar {
    background-color: transparent;
    &::before {
      transition: 0.3s;
    }
  }
  .simplebar-visible {
    &::before {
      opacity: 1 !important;
      transition: 0.3s;
      background-color: #068aa7;
    }
  }

  .popup-content {
    padding: 2.5rem 2rem;
    height: 100%;
    outline: 0;
    @media screen and (min-width: 360px) {
      padding: 2rem 2rem;
    }

    @media screen and (min-width: 400px) {
      padding: 2rem 2rem;
    }

    @media screen and (min-width: 500px) {
      padding: 2rem 3rem;
    }

    @media screen and (min-width: $tablet) {
      padding: 2rem 3rem;
    }

    @media screen and (min-width: $desk_sm) {
      padding: 3.2rem 4rem;
    }
  }

  &__close-icon {
    position: absolute;
    width: 22px;
    height: 22px;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }

  .icon-wrapper {
    display: none;
    margin-bottom: 20px;
    justify-content: center;
  }

  &_success {
    .success-icon {
      display: flex;

      @include icon;
    }
  }
  &_common {
  }
  &_warning {
    border: 1px solid yellow;
  }
  &_error {
    .unsuccess-icon {
      display: flex;

      @include icon;
    }
  }

  .form-popup {
    display: flex;
    flex-direction: column;
  }
}
