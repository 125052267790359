@import '../../../style/breakpoints';

.page-preload {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-wrapper {
  position: absolute;
  background-color: white;
  z-index: 1;
  img {
    max-width: 300px;
    animation: logo-anim 2.5s infinite;
    @media screen and (min-width: $tablet) {
      max-width: 380px;
    }
  }
}

.spin-wrapper {
  position: absolute;
  svg {
    path {
      stroke-linecap: round;
    }
  }
}

@keyframes logo-anim {
  0% {
    transform: scale(0.85);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.85);
  }
}
