@import '../../../style/color';

.reset-field {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  svg {
    fill: #808080;
    width: 100%;
    height: 100%;
    transition: 0.3s;
  }
  &:focus-visible {
    outline: 1px solid black;
  }
  &:disabled {
    svg {
      fill: $disable__color_dark;
      transition: 0.3s;
    }
  }
  &:not(:disabled) {
    &:hover {
      svg {
        fill: $first__color_hover;
        transition: 0.3s;
      }
    }
  }
  &:not(:disabled) {
    &:active {
      svg {
        fill: $first__color_active;
        transition: 0.3s;
      }
    }
  }
}

.disabled,
*:disabled {
  .reset-field {
    cursor: default;
    svg {
      fill: #808080 !important;
    }
  }
}
