.help-block {
  display: flex;
  &__item{
    align-self: flex-start;
  }
  &__item_faq {
    width: 65%;
    padding: 15px 25px;
  }
  &__item_feedback {
    padding: 25px;
    width: 35%;
    box-shadow: 0px 0px 67px -23px rgb(34 60 80 / 15%);
    margin: 10px 25px;
    position: sticky;
    position: -webkit-sticky;
    top: 110px;
  }

  &__title {
    text-align: center;
    margin-bottom: 20px;
  }

  .left{
    order: 1;
  }
  .right{
    order: 2;
  }
}