@import '../../../../../style/color';
@import '../../../../../style/field/field';

.input-wrapper {
  &__field {
    display: flex;
    border-radius: $field-border-radius;
    overflow: hidden;
    background-color: $field-color-ligth;
    border: 1px solid $field-color-ligth;
    &.disabled {
      background-color: white;
      .option-bar {
        background-color: white;
      }
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
    transition: 0.3s;
    opacity: 0.5;
    font-weight: 400;
    font-size: 14px;
    pointer-events: none;
  }
  &__input {
    // position: absolute;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $field-color-ligth inset !important;
    }

    &:-webkit-autofill:disabled {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      -webkit-text-fill-color: rgba($color: #000000, $alpha: .5) !important; /* цвет текста */
    }

    left: 0;
    display: block;
    width: 90%;
    height: 100%;
    border: 0;
    background-color: transparent;
    outline: none;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    caret-color: $first__color;
    padding: 15px 12px 8px 0px;
    transition: border-color 0.2s;
    line-height: 24px;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &:disabled {
      background-color: white;
      color: rgba($color: #000000, $alpha: 0.5);
    }
    // &:hover {
    //   border-color: hsl(0, 0%, 70%);
    //   transition: border-color 0.2s;
    // }
    // &:focus {
    //   border-color: $first__color;
    //   transition: border-color 0.2s;
    // }
    &::placeholder {
      font-size: 0.8rem;
      opacity: 0.9;
    }
    &.basic-placeholder {
      padding-top: 0;
    }
  }
  &.error {
    .input-wrapper__input {
      // border-color: red;
      // color: red;
    }
    .input-wrapper__placeholder {
      // border-color: red;
      // color: red;
    }
  }
  &__input:focus ~ &__placeholder,
  &__input:-webkit-autofill ~ &__placeholder,
  &__input:not([value='']) ~ &__placeholder,
  &__placeholder_active {
    transform: translateY(-135%);
    font-size: 13px;
    transition: 0.3s;
  }
  &__input:focus + .option-bar {
    opacity: 0.85;
    transition: 0.3s;
  }
  .option-bar {
    display: flex;
    right: 0;
    padding-right: 20px;
    padding-left: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
    height: 100%;
    background-color: $field-color-ligth;
    position: absolute;
    & > * {
      opacity: 0.5;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      &:hover {
        opacity: 1;
      }
    }
  }
}
