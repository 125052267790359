@import '../../../../../style/breakpoints';

.wrapper {
  display: flex;
}

.newsSorting {
  display: flex;
  justify-content: space-between;
  z-index: 2;
  flex: 1;
  padding: 0 20px;

  .input-wrapper {
    margin: 0;
  }

  @media screen and (min-width: $desk_lg) {
    padding: 0;
    margin-right: 10px;
  }

  .wrapperSelect {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;

    @media screen and (min-width: $desk_sm) {
      justify-content: unset;
    }
  }

  .sorting {
    flex-direction: column;

    @media screen and (min-width: $tablet) {
      flex-direction: unset;
    }

    @media screen and (min-width: $desk_lg) {
      justify-content: flex-start;
    }
  }

  .categories {
    min-width: 175px;
    margin-bottom: 30px;

    @media screen and (min-width: $tablet) {
      margin-right: 10px;
    }

    @media screen and (min-width: $desk_sm) {
      margin-bottom: 50px;
      justify-content: unset;
    }

  }

  .tags {
    min-width: 175px;
    margin-bottom: 30px;

    @media screen and (min-width: $tablet) {
      margin-right: 10px;
    }

    @media screen and (min-width: $desk_sm) {
      margin-bottom: 50px;
    }
  }

  .filter {
    display: none;
    @media screen and (min-width: $tablet) {
      margin-right: 10px;
    }

    @media screen and (min-width: $desk_sm) {
      margin-bottom: 50px;
    }
    @media screen and (min-width: $desk_lg) {
      display: flex;
      justify-content: flex-end;
      width: auto;
      margin-left: auto;
      //width: 213px;
    }
  }

  .new {
    width: 180px;
  }
}
