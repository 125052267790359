@import '../../../style/color';

.calendar-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  path {
    transition: 0.2s;
  }
  &:hover {
    path {
      stroke: $first__color_hover;
    }
  }
  &:active {
    path {
      stroke: $first__color_active;
    }
  }
}

*:disabled {
  .calendar-icon {
    path {
      stroke: rgb(157, 166, 186) !important;
    }
  }
}
