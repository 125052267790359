@import '../breakpoints';

.popup__title {
  text-align: center;
  &_lg {
    font-size: 2.5rem;
    margin: 1rem;
  }
  &_md {
    font-size: 25px;
    font-weight: 700;
    @media screen and (min-width: $desk_sm) {
      font-size: 35px;
    }
  }
  &_sm {
    font-size: 1rem;
    margin: 0.75rem;
  }
  margin-bottom: 5px;
}

.popup__text {
  text-align: center;
}
