@import '../../../../../style/color';
@import '../../../../../style/field/field';

.input-wrapper-text {
  &__field {
    display: block;
    position: relative;
    width: 100%;
    padding: 30px 21px 10px 0;
    border-radius: $textarea-border-radius;
    background-color: $field-color-ligth;
    .option-bar-text {
      display: flex;
      position: absolute;
      right: 21px;
      top: 20px;
      transform: translateY(-50%);
      opacity: 0.5;
      transition: 0.3s;
      & > * {
        width: 10px;
        height: 10px;
      }
    }
  }
  &__textarea {
    left: 0;
    display: block;
    width: 100%;
    min-height: 8vh;
    border: 0;
    resize: vertical;
    background-color: transparent;
    outline: none;
    padding: 0 14px 0 $field-padding-left;
    caret-color: $first__color;
    border: 1px solid $field-color-ligth;
    transition: border-color 0.2s;
    // &:hover {
    //   border-color: hsl(0, 0%, 70%);
    //   transition: border-color 0.2s;
    // }
    // &:focus {
    //   border-color: $first__color;
    //   transition: border-color 0.2s;
    // }
    scrollbar-color: white $field-color-ligth;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: white;
    }
    &::-webkit-resizer {
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.5L5.5 1M1 10L10 1' stroke='%2374767A'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      background-color: $field-color-ligth;
    }
    &::placeholder {
      font-size: 0.8rem;
      opacity: 0.9;
    }
  }
  &__textarea:focus ~ &__placeholder,
  &__textarea:not(:empty) ~ &__placeholder {
    transform: translateY(-80%);
    font-size: 13px;
    transition: 0.3s;
  }
  &__placeholder {
    position: absolute;
    top: 20px;
    left: $field-padding-left;
    transform: translateY(0%);
    z-index: 0;
    transition: 0.3s;
    opacity: 0.5;
    font-weight: 400;
    font-size: 13px;
  }
}
