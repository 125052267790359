@import '../../../style/breakpoints';

.change-pass-block {
  .fields-container {
    @media screen and (min-width: $tablet) {
      display: flex;
      .input-wrapper {
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }
  }
  .button-container {
    margin: 0 auto;
    @media screen and (min-width: $tablet) {
      width: 60%;
    }
  }
}
