@import './breakpoints';

.title {
  text-align: center;
  margin-bottom: 30px;
  @media screen and (min-width: $desk_sm) {
    margin-bottom: 50px;
  }
}

.title-lg {
  font-size: 30px;
  font-weight: 700;
  @media screen and (min-width: $desk_sm) {
    font-size: 55px;
    line-height: 60px;
  }
}

.title-md {
  font-size: 30px;
  font-weight: 600;
}

.title-sm {
  font-size: 18px;
  font-weight: 700;
  @media screen and (min-width: $desk_lg) {
    font-size: 24px;
  }
}
