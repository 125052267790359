@import '../../../../style/color';
@import '../../../../style/breakpoints';

.button-container {
  margin-top: 35px;
  padding-bottom: 20px;
}

form {
  position: relative;
  &:focus {
    outline: 0;
  }
  &:focus-within {
    outline: 0;
  }
  &:focus-visible {
    outline: 0;
  }
}

.horizontal {
  @media screen and (min-width: $tablet) {
    display: flex;
    margin-top: 25px;
    .fields-container {
      flex: 60%;
      .input-wrapper {
        margin: 0;
      }
    }
    .button-container {
      padding: 0;
      flex: 40%;
      .button-wrapper,
      button {
        // height: 100% !important;
      }
      margin: 0;
      margin-left: 15px;
    }
  }
}

.nav-row {
  position: relative;
  top: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;

  a {
    margin-top: 5px;
    color: $first__color;
  }
}
