@import '../../style/breakpoints';

.recaptha-v2 {
  @media screen and (min-width: $desk-sm) {
    // position: absolute;
    // transform: translateY(100%);
  }
}

.is-visible {
  // position: absolute;
  transform: translateY(15%);
}
