@import '../../../style/var';
@import '../../../style/color';
@import '../../../style/breakpoints';

.articleCard {
  padding: 0 20px;
  margin: 50px 0;
  @media screen and (min-width: $desk_lg) {
    padding: 0;
    margin: 70px 0 100px;
  }

  .news-footer {
    clear: both;

    margin-top: 20px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $desk_lg) {
      margin-top: 50px;
      flex-direction: unset;
      justify-content: space-between;
      align-items: center;
    }

    &__date {
      font-family: 'Circe', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #9da6ba;

      @media screen and (min-width: $desk_lg) {
        font-size: 18px;
        line-height: 130%;
      }
    }

    &__info {
      margin-top: 10px;

      @media screen and (min-width: $desk_lg) {
        margin-top: unset;
      }
    }
  }

  &__disc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: $font-size-card-disc;
    @media screen and (min-width: $desk_lg) {
      margin-bottom: 20px;
    }

    &_title {
      font-size: $font-size-card-article;
      color: $first__color;
      text-decoration: none !important;
      @media screen and (min-width: $desk_lg) {
        font-size: $font-size-card-article-desktop;
      }
    }

    .views {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__btn {
        display: flex;
        margin: 0;
        padding: 0;
        // cursor: pointer;
        color: $color-btn-like-card;
        background-color: transparent;
        border: none;

        &:not(:last-child) {
          padding-right: 10px;
        }

        &_time {
          display: none;
          @media screen and (min-width: $desk_lg) {
            display: block;
          }
        }
      }

      &__icon {
        margin-right: 2px;
      }
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: $articleCard_title;
    @media screen and (min-width: $desk_lg) {
      margin-bottom: 20px;
      font-size: $articleCard_title-desktop;
    }
  }

  &__disc-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: $color-btn-like-card;
    font-size: $font-size-news-btn;
    @media screen and (min-width: $desk_lg) {
      margin-bottom: 40px;
      font-size: $font-size-news-btn-desktop;
    }

    &_author {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &_time {
      @media screen and (min-width: $desk_lg) {
        display: none;
      }
    }
  }

  &__img {
    position: relative;
    z-index: 1;
    padding: 0 0 57%;
    //padding: 0 0 45%;
    margin: 0 auto 20px;

    @media screen and (min-width: $desk_lg) {
      padding: 0 0 45%;
      margin: 0 auto 50px;
    }

    //border: 1px solid red;

    img {
      margin: 0 auto;
      border-radius: 10px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
  }

  .sub-img {
    //padding: 0 0 100%;
    width: 280px;
    height: 280px;
    padding: 0;
    @media screen and (min-width: $desk_lg) {
      width: initial;
      height: initial;
      padding: 0 0 220px 555px;
      margin: 0;
    }
  }

  &__wrap {
    @media screen and (min-width: $desk_lg) {
      display: flex;
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    margin-bottom: 10px;
    font-size: $articleCard_subtitle;
    font-weight: 700;
    @media screen and (min-width: $desk_lg) {
      margin-bottom: 30px;
      font-size: $articleCard_subtitle-desktop;
    }
  }

  &__subtitle-center {
    text-align: center;
  }

  &__text {
    margin-bottom: 10px;
    font-size: $font-size-card-article;
    @media screen and (min-width: $desk_lg) {
      margin-bottom: 20px;
      font-size: $font-size-card-article-desktop;
    }
  }

  &__text-cursive {
    font-style: italic;
  }

  &__link {
    margin-bottom: 20px;
    font-size: $font-size-card-article;
    @media screen and (min-width: $desk_lg) {
      margin-bottom: 30px;
      font-size: $font-size-card-article-desktop;
    }

    a {
      color: $first__color;
      text-decoration: underline;
    }
  }

  &__img-disc {
    max-width: 505px;
    margin: 0 0 10px auto;
    text-align: right;
    font-size: $font-size-card-article;
    @media screen and (min-width: $desk_lg) {
      margin: 0 0 20px auto;
      font-size: $font-size-card-article-desktop;
    }
  }

  &__quote {
    padding: 30px 10px;
    background-color: $background-card;
    text-align: center;
    border-radius: 10px;
    @media screen and (min-width: $desk_lg) {
      padding: 70px 65px;
    }
  }

  &__quote-wrap {
    @media screen and (min-width: $desk_lg) {
      display: flex;
    }
  }

  &__quote-img {
    img {
      margin: 0 auto;
    }

    &_right {
      margin-bottom: 30px;
      @media screen and (min-width: $desk_lg) {
        margin: auto 0 0;
      }
    }
  }

  &__quote-text {
    margin: 20px 0;
    font-style: italic;
    font-size: $font-size-card-quote;
    @media screen and (min-width: $desk_lg) {
      max-width: 825px;
      width: 100%;
      margin: 0 auto 20px;
      font-size: $font-size-card-quote-desktop;
    }
  }

  &__author {
    font-size: $font-size-card-article;
    @media screen and (min-width: $desk_lg) {
      font-size: $font-size-card-article-desktop;
    }
  }

  &__line {
    margin: 30px 0;
    border: 1px solid $tooltip-card;
    @media screen and (min-width: $desk_lg) {
      width: 65%;
      margin: 30px auto;
    }
  }

  &__table-header {
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: $font-size-card-disc;
    color: $color-btn-like-card;

    margin-top: 35px;

    @media screen and (min-width: $desk_lg) {
      margin-top: 50px;
    }

    .button-wrapper {
      width: auto;
      margin-right: 10px;
    }
  }
  &__tag {
    padding: 5px 20px;
    margin: 0 10px 10px 0;
    border: 1px solid $border-card;
    box-sizing: border-box;
    border-radius: 8px;
  }
}

.like-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-btn-like-card;
  font-size: $font-size-card-disc;

  &__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__btn {
    display: flex;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: $color-btn-like-card;
    background-color: transparent;
    border: none;
    &:not(:last-child) {
      padding-right: 10px;
    }
  }
  &__icon {
    margin-right: 2px;
  }
}

.articleCard-like-wrap {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 31px;
  @media screen and (min-width: $desk_lg) {
    margin-bottom: 51px;
    flex-direction: row;
  }

  div {
    &:first-child {
      margin-bottom: 10px;
    }
  }
}
