@import '../../../style/breakpoints';

.page-form-wrapper {
  max-width: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0px 0px 67px -23px rgba(34, 60, 80, 0.15);
  padding: 25px 14%;
  @media screen and (min-width: $tablet) {
    padding: 25px 150px;
  }

  &__form-block {
    width: 100%;
  }
}
