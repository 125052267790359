@import '../../../style/color';
@import '../../../style/breakpoints';


.upload-check-wrapper{
  margin-top: 50px;
  @media screen and (min-width: $desk_sm) {
    display: none;
  }
}

.personal-page-block {
  margin: 30px 5px;
  box-shadow: 0px 0px 67px -23px rgb(34 60 80 / 15%);
  box-shadow: 15px;
  padding: 0 5% 40px;

  @media screen and (min-width: $desk_sm) {
    margin: 50px;
    padding: 0 50px 40px;
  }

  &__header {
    display: flex;
    margin-bottom: 25px;
    overflow-x: auto;

    &::-webkit-scrollbar{
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb{
      width: 0;
      height: 0;
    }

    .header__item {
      &:first-child{
        margin-left: 0;
      }
      color: black;
      padding: 25px 10px 10px;
      margin: 0 5px;
      text-decoration: none !important;
      border: 0;
      border-bottom: 2px solid transparent;
      transition: none;

      text-align: center;

      &:hover {
        border-color: rgba($color: $first__color, $alpha: .5);
      }

      &.active{
        border-color: $first__color;
      }
    }
  }

  &__user-data {
    margin-top: 15px;
  }
}

.user-data {
  &__info-block {
    margin: 5px 0;
  }
  &__caption {
    font-weight: bold;
  }
  &__value {
    word-wrap: break-word;
  }
}
