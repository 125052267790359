.plugpage {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.plug {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.head {
  font-size: 35px;
  margin-bottom: 35px;
}

.clock {
  margin: 0 auto;
  width: 300px;
  font-size: 18px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media screen and (min-width: 620px) {
    width: 600px;
    font-size: 30px;
  }
  .numb {
    position: relative;
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .word {
      position: absolute;
      bottom: -30px;
    }
  }
}
