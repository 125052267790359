@import '../../../style/breakpoints';

.status-code {
  font-size: 120px;
  line-height: 120px;
  font-weight: bold;
  text-align: center;
  opacity: 0.1;
  margin: 0 auto;
  width: min-content;

  box-shadow: 0px 30px 15px -20px rgba(0, 0, 0, 0.5);

  @media screen and (min-width: $desk_sm) {
    font-size: 270px;
    line-height: 220px;
  }
}
