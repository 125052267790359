@import '../../../../../../style/color';
@import '../../../../../../style/field/field';

.input-wrapper {
  width: 100%;
  margin: 1.7em 0;
  margin-top: 0;
  position: relative;
  display: block;

  &__label {
    flex-grow: 1;
    font-size: 0.95rem;
    line-height: 1.4rem;
  }

  &__field {
    display: block;
    position: relative;
    width: 100%;
    height: $field-height;
    padding: 0 10px 0 $field-padding-left;
  }
}

.promo-select__indicator.promo-select__dropdown-indicator {
  padding: 0 10px 0 0;
}
