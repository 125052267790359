@import '../../../../style/color';
@import '../../../../style/breakpoints';

.product-card {
  border: 1px solid $field-color-ligth;
  height: 100%;
  width: 100%;
  max-width: 330px;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &__preview {
    margin-bottom: 20px;
    height: 200px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    img {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    p {
      margin: 0;
      font-size: inherit;
      font-weight: inherit;
    }
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-height: 26px;
    word-wrap: break-word;
    @media screen and (min-width: $desk_sm) {
      line-height: 31px;
    }
  }

  &__description {
    p {
      margin: 0;
    }
    overflow: hidden;
    display: block;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-height: 23px;
    max-height: 120px;
    margin-top: 10px;
    @media screen and (min-width: $desk_sm) {
      max-height: 92px;
    }
  }

  &__button-wrapper {
    margin-top: auto;
    width: 154px;
    padding-top: 20px;
    @media screen and (min-width: $desk_sm) {
      margin-bottom: 10px;
    }
  }

  &__button {
    display: block;
    &.normal {
      .button.default {
        font-weight: 700 !important;
        font-size: 16px;
      }
    }
  }
}
