@import '../../../../../style/color';
@import '../../../../../style/field/field';

.input-wrapper {
  &__select {
    &_error {
      .promo-select__control {
        border-color: red !important;
      }
    }
  }
}

.promo-select {
  * {
    // border: 0 !important;
    transition: none;
  }

  &__single-value {
    font-weight: 700;
    font-size: 14px;
  }

  &_default &__control {
    height: $field-height;
    border: 0px solid transparent !important;
    background-color: $field-color-ligth;
    border-radius: $field-border-radius;
    .drop-down-icon {
      margin-right: 16px !important;
    }
    &:hover {
      border: 1px solid transparent;
    }
    &--is-disabled {
      background-color: white !important;
      border: 1px solid $field-color-ligth !important;
    }
    &--menu-is-open {
      border-radius: $field-border-radius $field-border-radius 0 0 !important;
      .drop-down-icon {
        transform: rotate(180deg) !important;
        transition: 0.3s;
        svg {
          fill: $first__color_hover !important;
        }
      }
    }
    &--is-focused {
      border: 1px !important;
      box-shadow: none !important;
    }
  }

  &_default &__value-container {
    padding: 0 0 0 $field-padding-left !important;
  }

  &_default &__menu {
    overflow: hidden !important;
    width: 100% !important;
    background-color: $field-color-ligth !important;
    top: 47px !important;
    box-shadow: none !important;
    border: 0 !important;
    border-radius: 0 0 8px 8px !important;
    & > * {
      padding: 0;
      scrollbar-color: white $field-color-ligth;
      scrollbar-width: thin;
    }
    & > *::-webkit-scrollbar {
      width: 12px !important;
    }
    & > *::-webkit-scrollbar-thumb {
      background-color: white;
      border-radius: 10px;
      border: 3px solid $field-color-ligth;
    }
  }

  &_default &__placeholder {
    font-size: 14px;
  }

  &_small &__control {
    height: 43px;
    background-color: white;
    border: 1px solid $second__color_light !important;
    border-radius: $field-border-radius;
    cursor: pointer;

    &--menu-is-open {
      border-radius: $field-border-radius $field-border-radius 0 0 !important;
      .drop-down-icon {
        transform: rotate(180deg) !important;
        transition: 0.3s;
      }
    }
    &--is-focused {
      box-shadow: none !important;
    }
  }

  &_small &__menu {
    overflow: hidden !important;
    background-color: white;
    margin-top: 0 !important;
    box-shadow: none !important;
    border: 1px solid $field-color-ligth;
    border-radius: 0 0 8px 8px !important;

    & > * {
      padding: 0;
      scrollbar-color: white $field-color-ligth;
      scrollbar-width: thin;
    }
    & > *::-webkit-scrollbar {
      width: 4px !important;
    }
    & > *::-webkit-scrollbar-thumb {
      background-color: $field-color-ligth;
    }
  }

  &_small &__placeholder {
    font-size: 18px;
    color: $second__color_dark !important;
  }

  &_small &__single-value {
    font-size: 18px;
  }

  &__indicator-separator {
    display: none !important;
  }

  .drop-down-icon {
    transform: rotate(0deg) !important;
    transition: 0.3s;
    margin-right: 9px;
    svg {
      fill: hsl(0, 0%, 69%) !important;
      height: 20px !important;
      width: 20px !important;
    }
  }

  &__option {
    padding: 16px !important;
    // border-right: 0;
    font-weight: 700;
    cursor: pointer !important;

    word-break: break-word;
    overflow-wrap: break-word;

    &:active {
      color: #fff;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $field-color-ligth_hover;
    }
  }

  &_small &__option {
    padding: 11px 16px !important;

    &:not(:last-of-type) {
      border-bottom: 1px solid $field-color-ligth;
    }
  }
}
