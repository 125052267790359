.table-upload-checks-block{
  max-width: 750px;
  margin: 0 auto;
  margin-top: 50px;
  text-align: center;
  &__header{
    margin-bottom: 25px;
  }
  table{
    td, th{
      text-align: center;
    }
  }
}