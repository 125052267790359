@import '../../../../../../style/color';
@import '../../../../../../style/var';
@import '../../../../../../style/breakpoints';
@import '../../../../../../style/font-family';

.cardWrap {
  position: relative;
  padding: 20px;
  border: 1px solid $border-card;
  background-color: $background-card;
  border-radius: 10px;

  &_read {
    background-color: white;
  }

  width: 100%;
  .card-wrapper {
    width: 100%;
  }

  &-tile {
    margin: 0 auto;
    max-width: 280px;
    min-height: 523px;

    @media screen and (min-width: $tablet) {
      max-width: 330px;
    }

    @media screen and (min-width: $tablet) {
      max-width: none;
    }

    @media screen and (min-width: $desk_lg) {
      min-height: 532px;
    }
  }

  &-line {
    margin-bottom: 30px;
    @media screen and (min-width: $tablet) {
      display: flex;
      max-width: 1100px;
      padding: 30px;
    }
    @media screen and (min-width: $desk_sm) {
      max-height: 328px;
    }
  }

  &_read {
    background-color: $second_color;
  }

  &__img {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    padding-bottom: calc(100% / 12 * 10);
    // aspect-ratio: 12 / 10;

    @media screen and (min-width: $desk_lg) {
      padding-bottom: 260px;
    }

    img {
      border-radius: 10px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-position: top;
      object-fit: cover;
      @media screen and (min-width: $desk_sm) {
        max-height: 260px;
        height: 100%;
      }
    }
  }

  &-line {
    .cardWrap__img {
      // @media screen and (min-width: $tablet) {
      //   padding: 0;
      //   width: 264px;
      //   height: 220px;

      //   aspect-ratio: 12 / 10;

      //   margin: 0 36px 8px 0;
      // }
      @media screen and (min-width: $desk_sm) {
        padding: 0;
        width: 310px;
        height: 260px;
        flex-shrink: 0;

        // aspect-ratio: 12 / 10;

        margin: 0 40px 8px 0;
      }
    }
  }

  &-line {
    .cardWrap__tooltip {
      left: 10px;
      right: auto !important;
    }
  }

  &__tooltip {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px 15px;
    background: $background-card;
    border-radius: 8px;
    z-index: 2;
    color: $tooltip-card;
    font-size: 12px;
    line-height: 18px;

    @media screen and (min-width: $desk_sm) {
      padding: 6px 20px;
      font-size: 14px;
      line-height: 1.3;
    }

    &_list {
      display: none;
      position: absolute;
      bottom: 20px;
      left: 50%;
      color: $tooltip-card;
      font-size: $font-size-card-tooltip;
    }

    &_dark {
      background: $tooltip-card;
      color: $second_color;
      opacity: 1;
      // border: 1px solid $second_color;
    }
  }

  &__disc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: $font-size-card-disc;

    &_title {
      color: $first__color;
    }

    &_data {
      color: $tooltip-card;
    }
  }

  &-line {
    .cardWrap__disc {
      &_data {
        @media screen and (min-width: $tablet) {
          display: none;
        }
      }
    }
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: $font-size-card-title;
    line-height: 26.53px;
    @media screen and (min-width: $desk_lg) {
      margin-bottom: 20px;
      font-size: $font-size-card-title-desktop;
    }
  }

  &__article {
    margin-bottom: 10px;
    font-size: $font-size-card-article;
    line-height: 24px;

    height: 69px;
    overflow: hidden;

    @media screen and (min-width: $desk_lg) {
      font-size: $font-size-card-article-desktop;
    }
  }

  &__link {
    margin-bottom: 22px;
    @media screen and (min-width: $desk_lg) {
      margin-bottom: 81px;
    }

    a {
      text-decoration: underline;
      font-size: $font-size-card-link;
      font-weight: 700;
    }
  }

  .like-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-btn-like-card;
    font-size: $font-size-card-disc;
    line-height: 18px;

    bottom: 34px;
    right: 27px;
    position: absolute;
    width: 55%;

    @media screen and (min-width: $desk_sm) {
      width: 60%;
    }

    @media screen and (min-width: $desk_md) {
      width: 62.75%;
    }

    &__disc {
      &_data {
        display: none;
        @media screen and (min-width: $tablet) {
          display: block;
        }
      }
    }

    &__name-container {
      display: flex;
      margin-right: 4px;
    }

    &__name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 128px;
    }

    &__btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 4px;
    }

    &__btn {
      display: flex;
      margin: 0;
      padding: 0;
      cursor: pointer;
      color: $color-btn-like-card;
      background-color: transparent;
      border: none;

      &:not(:last-child) {
        padding-right: 10px;
      }
    }

    &__icon {
      margin-right: 2px;
      flex-shrink: 0;
    }
  }

  &-line {
    .like-wrap__name {
      margin-left: 20px;
      max-width: 168px;
    }
  }

  .views {
    display: none;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: $tablet) {
      display: flex;
    }

    &__btn {
      display: flex;
      margin: 0;
      padding: 0;
      // cursor: pointer;
      color: $color-btn-like-card;
      background-color: transparent;
      border: none;

      &:not(:last-child) {
        padding-right: 10px;
      }
    }

    &__icon {
      margin-right: 2px;
      flex-shrink: 0;
    }
  }
}

.cardWrap.active {
  background-color: inherit;
}

.cardWrap__tooltip_list.active {
  display: block;
}

.cardWrap-tile {
  .like-wrap {
    bottom: 20px;
    left: 18px;
    position: absolute;
    width: 88%;

    &__btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__btn {
      display: flex;
      margin: 0;
      padding: 0;
      cursor: pointer;
      color: $color-btn-like-card;
      background-color: transparent;
      border: none;

      &:not(:last-child) {
        padding-right: 10px;
      }
    }

    &__icon {
      margin-right: 2px;
      flex-shrink: 0;
    }

    &__name {
      max-width: 84px;

      @media screen and (min-width: $tablet) {
        max-width: 128px;
      }
    }
  }
}
