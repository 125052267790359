.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  z-index: 999;

  background-color: rgba($color: #000000, $alpha: 0.77);
  &_hidden {
    animation: hidePopup 0.2s forwards;
  }
  &_visible {
    animation: showPopup 0.2s forwards;
  }
}

@keyframes hidePopup {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    z-index: 1;
    visibility: hidden;
  }
}

@keyframes showPopup {
  0% {
    z-index: 3;
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
