$color-black: #000;

// MAIN THEME
$first__color: #089fc6;
$first__color_hover: #068aa7;
$first__color_active: #005772;

$second__color: #f0f0f2;
$second__color_light: #e4e8ee;
$second__color_dark: #9da6ba;


// DISABLE COLOR
$disable__color_light: #f0f0f2;
$disable__color_dark: #b2b3bc;

$second_color: #fff;

// FIELD
$field-color-ligth: #e8ecf3;
$field-color-ligth_hover: #e4e8ee;

// BUTTON
$btn_color: $first__color;
$btn_color-news: $first__color;

//NEWS CARD
$border-card: #e8ecf3;
$background-card: #e8ecf3;
$tooltip-card: #9da6ba;
$color-btn-like-card: #9da6ba;

//header
$color-header: $first__color;

//link
$color-link-active: #047f9a;
