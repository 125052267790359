@import '../../../style/breakpoints';
.block-form__wrapper {
  h3 {
    text-align: center;
  }
  padding: 50px 10%;
  margin: 50px auto;
  // box-shadow: 0px 0px 67px -23px rgb(34 60 80 / 15%);
  @media screen and (min-width: $tablet) {
    border-radius: 8px;
    max-width: 750px;
    padding: 70px 120px;
  }
}
