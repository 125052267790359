@import '../../../../../style/color';

.checkbox__wrpapper {
  display: flex;
}

.checkbox__flex-label {
  display: flex;
  align-items: flex-start;
  position: relative;
  bottom: 5px;
}

.input-wrapper {
  .checkbox-input-shadow {
    visibility: visible;
    margin: 0;
    height: 0;
    width: 0;
    opacity: 0;
  }

  &__label.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__label-text {
    margin-left: 10px;
    line-height: 21px;
    font-size: 12px;
    &.checkbox-label{
      // margin-top: 20px;
    }
    a{
      font-size: inherit;
    }
    &.non-click {
      margin-left: 8px;
    }
  }

  &__checkbox {
    border-radius: 4px;
    border: 1px solid grey;
    cursor: pointer;
  }
  .checkbox {
    background-color: #9DA6BA;
    border: 5px solid #9DA6BA;
    border-radius: 8px;
    transition: border .3s;
    &.hidden {
      display: none;
    }
    &__checked {
      width: 10px;
      height: 10px;
      padding: 5px;
      background-color: white;
      border-radius: 6px;
      box-sizing: content-box;
      background-size: 70%;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 1;
      transition: 0.2s;
    }
  }

  .checkbox-input-shadow:checked + &__checkbox {
    border-color: #56bcf4;
    background-color: #56bcf4;
    transition: .3s;
    .checkbox__checked {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.34133 0.223211C8.64849 0.520475 8.64807 1.00204 8.34041 1.29881L3.69713 5.77768C3.38983 6.07411 2.89213 6.07411 2.58483 5.77768L0.23102 3.50721C-0.0766452 3.21044 -0.0770591 2.72888 0.230095 2.43162C0.53725 2.13435 1.03566 2.13395 1.34332 2.43072L3.14098 4.16473L7.2281 0.222317C7.53577 -0.0744542 8.03418 -0.0740544 8.34133 0.223211Z' fill='%2356BCF4'/%3E%3C/svg%3E%0A");
      background-size: 50%;
      background-position: center;
      opacity: 1;
      transition: .02s;
    }
  }
  .checkbox-input-shadow:focus + &__checkbox {
    outline: solid 1px black;
  }
}
