.hint-wrapper{
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin: 1.5em 0;
}

.hint-input{
  width: 100%;
  flex: 1;
  .input-wrapper{
    margin: 0;
  }
}

.hint-block {
  position: relative;
  bottom: 13px;
  margin-left: 10px;
  &__pointer {
    cursor: pointer;
  }
  &__preview {
    opacity: 0;
    position: absolute;
    image-rendering: crisp-edges;
    right: 40px;
    top: -5200px;
    transition: opacity 0.1s;
    width: 185px;
    z-index: -1;
    transition: opacity 0.3s;
  }
}

.hint-wrapper:first-child{
  .hint-block__pointer:hover + .hint-block__preview {
    top: -100px;
  }
}

.hint-wrapper:last-child{
  .hint-block__pointer:hover + .hint-block__preview {
    top: -220px;
  }
}