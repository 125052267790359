@import '../../../../style/color';
@import '../../../../style/breakpoints';

.promo-product-slider {
  position: relative;
  margin: 25px 0;

  @media screen and (min-width: $desk_sm) {
    padding: 0 65px;
  }

  .navigation {
    position: absolute;
    bottom: 0;
    z-index: 10;
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (min-width: $desk_sm) {
      &.count-slider-1 {
        display: none;
      }
    }
    @media screen and (min-width: $desk_sm) {
      width: 100%;
      left: 0;
      bottom: 50%;
      transform: translateY(-50%);
      &.count-slider-2,
      &.count-slider-1 {
        display: none;
      }
    }
    & > * {
      cursor: pointer;
      border: 1px solid $first__color;
      background-color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
      @media screen and (min-width: $desk_sm) {
        width: 40px;
        height: 40px;
      }

      &:hover {
        background-color: $first__color;
        path {
          stroke: white;
          transition: 0.3s;
        }
        transition: 0.3s;
      }

      &:active {
        background-color: $first__color_active;
        border-color: $first__color_active;
        path {
          stroke: white;
        }
      }

      &:focus {
        box-shadow: 0 0 0 2px #fff, 0 0 0 3px $first__color;
      }

      &:focus-visible{
        outline: 0;
      }
    }
    &-next {
      transform: rotate(180deg);
    }
  }

  .swiper {
    padding-bottom: 50px !important;
    @media screen and (min-width: $desk_sm) {
      padding-bottom: 70px !important;
    }

    .swiper-pagination {
      bottom: 5px !important;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        opacity: 1;
        background-color: $second__color_dark;
        transition: .2s;

        &-active {
          background-color: $first__color;
        }

        &:hover{
          background-color: $first__color;
        }

        &:active{
          background-color: $first__color_active;
        }

        &:focus-visible{
          outline: none;
        }

        &:focus{
          box-shadow: 0 0 0 1px #fff, 0 0 0 2px $first__color;
        }
      }
    }

    .swiper-slide {
      height: auto !important;
    }
  }
}
