@import '../../../style/breakpoints';
@import '../../../style/font-family';
@import '../../../style/ui';

.main-container {
  font-family: $primaryFont;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  padding: 0 20px;

  @media screen and (min-width: $tablet) {
    max-width: 800px;
  }

  @media screen and (min-width: $desk_sm) {
    max-width: 1024px;
    padding: 0;
  }

  @media screen and (min-width: $desk_md) {
    max-width: 1180px;
  }

  @media screen and (min-width: $desk_lg) {
    max-width: 1110px;
  }
}
