@import '../../../style/breakpoints';

.personal-data {
  // padding: 0 50px;
  max-width: 550px;
  margin: 50px auto;

  &__header {
    margin-bottom: 25px;
    text-align: center;
  }

  &__rejection-reason {
    .rejection-block{
      // font-size: 16px;
      // border: 1px solid red;
      border-radius: 10px;
      padding: 2px 5px;
    }
  }

  .passport-photo{
    width: 300px;
    margin: 0;
  }

  .file-drop{
    // max-width: 400px;
    margin: 0 auto;
  }

  .personal-data-fields-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .input-wrapper {
      width: 100%;
      @media screen and (min-width: $tablet) {
        width: 48%;
      }
    }
  }

  .button-container {
    max-width: 400px;
    margin: 0 auto;
  }
}
