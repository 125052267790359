.email-verification-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .email-verification-card{
    padding: 100px 150px;
    box-shadow: 0px 0px 67px -23px rgba(34, 60, 80, 0.15);
  }
}