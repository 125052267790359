@import '../../style/color';
@import '../../style/font-family';
@import '../../style/breakpoints';

.content-container {
  @media screen and (min-width: $desk_sm) {
    margin-top: 80px;
  }
}

.post-preload-conntent {
  animation: appearance .7s;
}

.simplebar-content-wrapper {
  scroll-behavior: smooth;
}
.simplebar-scrollbar {
  background-color: #068aa7;
}
.simplebar-scrollbar::before {
  background-color: transparent;
}

#main-root {
  overflow: unset !important;
  font-family: $primaryFont;
}

// @keyframes appearance {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity .2s;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity .2s;
}