@import '../../../../../style/color';

.input-wrapper {
  .single-input-wrapper {
    margin: 0 7px;
    // margin-bottom: 1.5em;
    &__field {
      display: block;
      position: relative;
      width: 100%;
      height: 3.2rem;
      padding: 0rem 10px;
      border-radius: 4px;
    }
    &__input {
      border-radius: 4px;
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      background-color: transparent;
      outline: none;
      padding: 0 10px;
      // padding-top: 10px;
      text-align: center;
      background-color: $field-color-ligth;
      border: 1px solid $field-color-ligth;
      transition: border-color 0.2s;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      &_filled {
        border: 1px solid blue;
      }
      &:hover {
        border-color: hsl(0, 0%, 70%);
        transition: border-color 0.2s;
      }
      &:focus {
        border-color: $first__color;
        transition: border-color 0.2s;
      }
    }

    // .single-input-wrapper__input_error.single-input-wrapper__input_filled{
    //   border: 1px solid transparent;
    // }

    &__input:focus ~ &__placeholder,
    &__input:not([value='']) ~ &__placeholder {
      transform: translateY(-135%);
      transition: 0.3s;
    }
  }
  &.error{
    .single-input-wrapper{
      &__input{
        border: 1px solid red;
      }
    }
  }
}
