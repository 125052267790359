@import '../../../style/color';
@import '../../../style/var';
@import '../../../style/breakpoints';
@import '../../../style/field/field';

.button-wrapper {
  width: 100%;
  position: relative;

  .button.default {
    cursor: pointer;
    border-radius: 8px;
    background-color: $first__color;
    border: 1px solid $first__color;
    color: white;
    font-size: 16px;
    font-weight: 800;
    height: 48px;
    width: 100%;
    transition: 0.1s;

    &.button_outline {
      background-color: transparent;
      color: $first__color;
    }

    &:focus {
      outline: none;
      background-color: $first__color;
      border-color: $first__color_active;
      color: white;
    }

    &:not(:disabled) {
      &:hover {
        background-color: $first__color_hover;
        color: white;
        transition: 0.3s;
      }
    }

    &:not(:disabled) {
      &:active {
        background-color: $first__color_active;
        border-color: $first__color_active;
        color: white;
      }
    }
  }

  .button.small {
    width: 100%;
    background-color: white;
    border: 1px solid $field-color-ligth;
    color: $second__color_dark;
    font-size: 14px;
    border-radius: 8px;
    padding: 5px 20px;
    cursor: pointer;
    transition: 0.1s;

    &:not(:disabled) {
      &:focus {
        outline: none;
        background-color: white;
        border-color: $second__color_dark;
        color: $second__color_dark;
      }
    }

    &:not(:disabled) {
      &:hover {
        background-color: $field-color-ligth;
      }
    }

    &:not(:disabled) {
      &:active {
        background-color: $second__color_dark;
        border-color: $second__color_dark;
        color: white;
      }
    }
  }

  .button {
    &:disabled {
      border-color: $disable__color_light;
      background-color: $disable__color_light;
      color: $disable__color_dark;
      cursor: not-allowed;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
