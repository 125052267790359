@import '../../../style/breakpoints';
@import '../../../style/color';
@import '../../../style/button/button';

.button-news {
  background-color: white;
  border: 1px solid $field-color-ligth_hover;
  border-radius: $field-border-radius;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  display: none;
  align-items: center;
  transition: 0.2s;

  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px;
  }

  svg {
    transition: 0.2s;
    fill: $second__color_dark;
  }

  &:hover {
    background-color: $second__color_dark;
    border-color: $second__color_dark;
    svg {
      fill: white;
    }
  }

  &.active {
    background-color: $second__color_dark;
    svg {
      fill: white;
    }
  }

  @media screen and (min-width: $desk_sm) {
    display: flex;
  }
}

.newsPagination {
  &__btn {
    padding: 12px 36px;
    background-color: transparent;
    border: 1px solid $btn_color-news;
    border-radius: 8px;
    color: $btn_color-news;
    font-weight: 800;
    font-size: $font-size-news-btn;
    cursor: pointer;
    &:hover {
      background-color: $btn_color-news;
      color: $second_color;
      transition: 0.2s;
    }
  }
}
