@import '../../../../style/breakpoints';

.product-grid{
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 31px;
  grid-column-gap: 31px;

  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: $desk_sm) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.add-product{
  max-width: 250px;
  margin: 0 auto;
  margin-top: 35px;
}