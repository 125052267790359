


//NEWS CARD
$font-size-card-tooltip: 12px;
$font-size-card-tooltip-desktop: 14px;

$font-size-card-disc: 14px;

$font-size-card-title: 18px;
$font-size-card-title-desktop: 24px;

$font-size-card-article: 16px;
$font-size-card-article-desktop: 18px;

$font-size-card-link: 16px;

$font-size-news-btn: 16px;
$font-size-news-btn-desktop: 20px;

$articleCard_title: 30px;
$articleCard_title-desktop: 55px;

$articleCard_subtitle: 16px;
$articleCard_subtitle-desktop: 24px;

$font-size-card-quote: 22px;
$font-size-card-quote-desktop: 32px;

$font-size-table: 12px;
$font-size-table-desktop: 18px;

//z-index
$z-index-header-menu: 10;