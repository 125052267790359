@import '../../../style/breakpoints';

.welcome-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding: 50% 0;
  margin: 50px 0;
  @media screen and (min-width: $desk_sm) {
    padding: 0;
  }
  &__title {
    text-align: center;
    margin-bottom: 35px;
  }
  &__description {
    text-align: center;
    @media screen and (min-width: $desk_sm) {
      width: 70%;
      margin: 0 auto;
    }
  }
}
