.code-block {
  position: relative;
  .field-row {
    padding: 0 50px;
    display: flex;
  }
  .error-code {
    color: red;
    position: absolute;
    text-align: center;
    bottom: 0px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
  }
}

.repeat-send-code{
  position: relative;
  top: 10px;
  text-align: center;
}

.send-code {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
