@import '../../style/breakpoints';

.error-layout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;

  .side-elements {
    & > * {
      position: absolute;
      @media screen and (min-width: $desk_sm) {
        display: block !important;
      }
    }

    &__cursor-and-ball {
      display: none;
      @media screen and (min-width: $desk_sm) {
        left: 7%;
        top: 15%;
      }
    }

    &__close-button {
      display: none;
      @media screen and (min-width: $desk_sm) {
        left: 7%;
        bottom: 17%;
      }
    }

    &__warning-block {
      left: 50%;
      top: -11vw;
      transform: translateX(-45%) rotate(-15deg) scale(0.75);

      @media screen and (min-width: 400px) {
        top: -17vw;
      }
      @media screen and (min-width: 500px) {
        top: -90px;
      }

      @media screen and (min-width: $desk_sm) {
        right: 7%;
        top: 5%;
        left: auto;
        transform: translateX(0);
      }
    }

    &__ball {
      right: 17%;
      bottom: 19%;
    }
  }

  .error-block {
    position: relative;
    z-index: 10;
    padding-top: 65px;

    @media screen and (min-width: $desk_sm) {
      padding-top: 0;
    }

    &__name {
      text-align: center;
      padding: 0 15px;
      max-width: 635px;
    }
    &__status {
      margin-bottom: 45px;
    }
    &__button {
      margin: 0 auto;
      margin-top: 10px;
      max-width: 220px;
      @media screen and (min-width: $desk_sm) {
        margin-top: 30px;
      }
    }
  }

  &_fullscreen {
    min-height: 100vh;

    .side-elements {
      &__warning-block {
        top: -99%;

        @media screen and (min-height: 680px) {
          top: 0%;
        }
      }
    }

    .error-block {
      padding-top: 0;
    }
  }
}
