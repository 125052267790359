.qr-scanner-wrapper{
  video{
    height: 100%;
  }
}

.scan-preloader{
  height: 322px;
  display: flex;
  align-items: center;
  justify-content: center;
  .preloader-wrapper{
    .preloader{
      top: 40%;
    }
  }
}

.qr-scanner-finder{
  position: absolute;
  top: 0;
  display: flex;
  flex-grow: 1;
  background-image: url(../../../../../assets/img/scan/scan-view-finder.png);
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
  height: 100%;
  width: 100%;
}