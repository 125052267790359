@import '../../../style/breakpoints';

.anchor {
  --anchor-header-height: 64px;
  --anchor-offset: 100px;

  position: relative;
  top: calc(
    -1 * var(--anchor-header-height) - var(--anchor-offset, 15px) + var(--anchor-adjustment, 0px)
  );

  @media screen and (min-width: $desk-sm) {
    --anchor-header-height: 76px;
  }
}
