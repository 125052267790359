@import '../../style/color';

.icons {
  margin: 10px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.sn {
  --primary: #{$second-color};
  --secondary: #{$first__color_hover};
  --accent: #{$second-color};

  display: block;
  width: 30px;
  height: 30px;

  &:not(:first-child) {
    margin-left: 5px;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:hover {
    --primary: #{$first__color};
    --secondary: #{$second-color};
    --y: -2px;
  }

  &:focus-visible{
    outline: 2px solid $second__color_dark;
  }

  &_accent {
    --primary: #{$first__color};
    --secondary: #{$second-color};
    --accent: #{$first__color};

    &:hover {
      --primary: #{$second-color};
      --secondary: #{$first__color};
    }
  }
}

.icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition-duration: 0.3s;
  transition-property: transform, fill;

  // border-radius: 5px;

  fill: var(--secondary);
  transform: translateY(var(--y, 0));

  path {
    fill: var(--primary);
    transition: fill 0.3s;
  }

  rect {
    stroke: var(--accent);
  }
}
