@import '../../../style/color';
@import '../../../style/breakpoints';
@import '../../../style/var';

.header {
  border-bottom: 1px solid $color-header;
  background-color: hsla(0, 0%, 100%, 0.98);
  //box-shadow: 0px 0px 80px 10px rgba(0, 0, 0, 0.05);
  z-index: 15;
  width: 100%;
  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    height: 60px;
    @media screen and (min-width: $desk_sm) {
      height: 75px;
    }
  }
  @media screen and (min-width: $desk_sm) {
    position: fixed;
  }
}

.header-container {
}

.header-logo-block {
  position: relative;
  z-index: 11;
  margin-right: auto;
  flex: 200px;
  .header-menu__link {
    height: 100%;
  }
  .logo-container {
    height: 50px;
    max-width: 200px;
    overflow: hidden;
  }
  .logo-image {
    opacity: 0;
    height: 100%;
  }
}

.header-logo {
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.header-menu {
  width: 100%;
  height: calc(100% - 50px);
  padding: 0 20px;
  position: absolute;
  top: -2000px;
  z-index: 4;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.4s;

  &_open {
    background-color: white;
    margin-top: 50px;
    transition: 0.4s;
    top: 0;
    z-index: $z-index-header-menu;
  }

  @media screen and (min-width: $desk_sm) {
    position: static;
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    &.navbar {
      margin-top: 30px;
      flex-grow: 1;
      align-items: flex-start;
      @media screen and (min-width: $desk_sm) {
        margin-top: 0;
        justify-content: center;
        align-items: center;
      }
    }
    &.sign-in {
      width: 280px;
      margin: 0 auto;
      margin-bottom: 15vh;
      @media screen and (min-width: $desk_sm) {
        width: 285px;
        margin-bottom: 0px;
      }
    }

    .nav-btn__wrapper {
      width: 100%;
      margin-bottom: 15px;
      @media screen and (min-width: $desk_sm) {
        width: 150%;
        margin-bottom: 0;
        &:last-child {
          width: 100%;
          margin-left: 20px;
        }
      }
    }
    @media screen and (min-width: $desk_sm) {
      position: static;
      flex-direction: row;
    }
  }

  &__item.navbar &__link {
    @media screen and (min-width: $desk_sm) {
      margin: 0 20px !important;
    }
  }

  &__link {
    display: inline-block;
    background-color: transparent;
    padding: 0.3rem 5px;
    margin: 0 0.3rem;
    font-size: 16px;
    font-weight: 400;
    color: $color-black;
    text-decoration: none;
    cursor: pointer;

    &_active {
      color: $first__color;
    }

    &_popup {
      color: $color-black;
    }
  }
}

.burger-container {
  height: 30px;
  width: 34px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;

  .close-burger {
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.4535 0.546545C14.7249 -0.18209 13.5472 -0.18209 12.8186 0.546545L8.00006 5.3652L3.18148 0.546476C2.45285 -0.182159 1.27513 -0.182159 0.546508 0.546476C-0.182115 1.27511 -0.182115 2.45285 0.546508 3.18149L5.36509 8.00021L0.546467 12.819C-0.182156 13.5476 -0.182156 14.7254 0.546467 15.454C0.909847 15.8174 1.3869 16 1.86395 16C2.341 16 2.81806 15.8174 3.18144 15.454L8.00006 10.6352L12.8186 15.454C13.1819 15.8174 13.659 16 14.136 16C14.6131 16 15.0902 15.8174 15.4535 15.454C16.1822 14.7254 16.1822 13.5476 15.4535 12.819L10.635 8.00021L15.4535 3.18156C16.1822 2.45292 16.1822 1.27518 15.4535 0.546545Z' fill='%23089FC6'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }

  .burger-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $first__color;
    border-radius: 3px;
    .header-burger {
      width: 20px;
      height: 2px;
      position: relative;
      background-color: white;
      border-radius: 3px;
      &::before,
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: white;
        position: absolute;
        border-radius: 3px;
      }
      &::before {
        top: -7px;
      }
      &::after {
        bottom: -7px;
      }
    }
  }
}
