@import '../../../../../style/breakpoints';
@import '../../../../../style/color';
@import '../../../../../style/var';

.newsButtonShow {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto 50px;
  z-index: 2;
  @media screen and (min-width: $tablet) {
    padding: 0 22px;
  }
}