@import '../../../style/color';
@import '../../../style/breakpoints';

.faq-wrapper {
  --anchor-adjustment: 40px;
  @media screen and (min-width: $desk_sm) {
    --anchor-adjustment: 70px;
  }
}

.faq-header {
  padding-bottom: 40px;
  text-align: center;
  @media screen and (min-width: $desk_sm) {
    padding-bottom: 70px;
  }
}

.faq-item {
  width: 100%;
  cursor: pointer;
  border: 0;
  border-top: 1px solid $second__color_dark;
  background-color: transparent;
  overflow: hidden;
  display: block;
  flex-direction: column;
  transition: 0.2s;
  line-height: 24px;
  a {
    font-size: inherit;
  }
  @media screen and (min-width: $desk_sm) {
    font-size: 20px;
    line-height: 29px;
  }

  .faq-item__icon {
    position: relative;
    bottom: 5px;
    display: flex;
    align-items: center;
    transition: 0.7s;
    margin-left: auto;
    min-width: 30px;
    min-height: 30px;
    transform: rotate(0deg);
    // background-image: url(./pointer/pointer.svg);
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    svg {
      transition: 0.3s;
      fill: $first__color_hover;
    }
  }

  &:last-child {
    border-bottom: 1px solid $second__color_dark;
    margin-bottom: 100px;
    &:hover {
      border-color: $first__color;
    }
  }

  &:focus-within {
    outline: none;
    border-color: $first__color_hover;
    .faq-title {
      color: $first__color_hover;
    }
    .faq-item__icon {
      svg {
        fill: $first__color_hover;
      }
    }
  }

  &:focus-within + .faq-item {
    border-color: $first__color;
  }

  &:hover {
    border-color: $first__color;
    .faq-title {
      color: $first__color;
    }
    .faq-item__icon {
      svg {
        fill: $first__color;
      }
    }
  }

  &:hover + .faq-item {
    border-color: $first__color;
  }

  &_active {
    border-color: $first__color_active;
    .faq-item__icon {
      svg {
        fill: $first__color_active;
      }
    }
  }

  &_active + .faq-item {
    border-color: $first__color_active;
  }

  &:active {
    border-color: $first__color_active;
    .faq-title {
      color: $first__color_active;
    }
    .faq-item__icon {
      svg {
        fill: $first__color_active;
      }
    }
  }

  &:active + .faq-item {
    border-color: $first__color_active;
  }

  &__question {
    display: flex;
    padding: 30px 0;
    outline: none;
    width: 100%;
    text-align: start;
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    .faq-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      transition: 0.3s;
      @media screen and (min-width: $desk_sm) {
        font-size: 24px;
      }
    }
    &_visible {
      .faq-title {
        color: $first__color_active;
        transition: 0.3s;
      }
      .faq-item__icon {
        transform: rotate(135deg);
        transition: 0.7s;
      }
    }
  }

  &__question_visible + &__answer {
    padding-bottom: 20px;
  }

  &__answer {
    overflow: hidden;
    transition: 0.5s;
    padding-bottom: 0;
    transition: 0.5s;
    visibility: hidden;
    text-align: start;
    &_visible {
      visibility: visible;
    }
    table {
      min-width: 280px;
      margin: 0 auto;
      max-width: calc(100vw - 40px) !important;
    }
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}
