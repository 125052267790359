.preloader-wrapper{
  .preloader{
    position: absolute;
    top: 20%;
    left: 50%;
    &::before{
      position: absolute;
      top: 0;
      content: '';
      animation: load .7s infinite;
      transform: translate(-50%, -50%);
      line-height: 0;
      font-size: 3rem;
    }
  }
}

@keyframes load {
  0% {
    content: "";
  }
  15% {
    content: ".";
  }
  35% {
    content: "..";
  }
  50% {
    content: "...";
  }
  65% {
    content: "..";
  }
  85% {
    content: ".";
  }
  100% {
    content: "";
  }
}