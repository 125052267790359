@import '../../../style/color';
@import '../../../style/breakpoints';

.footer {
  box-shadow: 0px 0px 80px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  padding: 40px 1rem 50px;
  background-color: $first__color_hover;

  &,
  a {
    font-size: 0.9rem;
    color: white;
  }
  & a {
    text-decoration: underline;
  }
  &__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.footer-content {
  .copyright-root {
    font-size: 14px;
    line-height: 18px;
    * {
      font-size: inherit;
      line-height: inherit;
    }
    p {
      margin: 10px 0;
    }
  }

  .service-info {
    font-size: 14px;
    // margin: 10px 0;
    &__item {
      &:first-child {
        margin-bottom: 10px;
      }
      display: block;
    }
    a {
      &:hover {
        color: $first__color_active !important;
      }
    }
  }
}

.footer-inner {
  width: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $tablet) {
    &_left {
      flex-direction: row-reverse;
      justify-content: space-between;
      p {
        text-align: right;
      }
    }

    &_right {
      flex-direction: row;
      justify-content: space-between;
      p {
        text-align: left;
      }
    }
  }
}

.footer-media {
  width: 100%;
  display: flex;

  flex-direction: column;
  & > *:last-child {
    margin-top: 10px;
  }

  &.md\:align-left {
    flex-direction: column;
    @media screen and (min-width: $desk_sm) {
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }

  &.md\:align-right {
    flex-direction: column;
    @media screen and (min-width: $desk_sm) {
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

@media screen and (min-width: $tablet) {
  .md\:align-left {
    text-align: left;
    align-self: flex-start;
  }

  .md\:align-right {
    text-align: right;
    align-self: flex-end;
  }
}
