@import '../../../../../style/color';
@import '../../../../../style/breakpoints';
@import '../../../../../style/field/field';
@import '../../../../../style/font-family';

.shadow-input {
  opacity: 0;
}

$wrap-date-time-field-viewport: 400px;

.datetime {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media screen and (min-width: $wrap-date-time-field-viewport) {
    flex-direction: row;
  }
}

.date-time-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex: 50% 0;
  justify-content: space-between;
  flex-direction: column;
  &.datetime {
    flex: 47% 0;
  }
  &:last-child {
    margin-top: 1em;
    @media screen and (min-width: $wrap-date-time-field-viewport) {
      margin-left: 11px;
      margin-top: 0;
    }
  }
}

.error {
  .picker {
    transition: 0.3s;
  }
}

.picker {
  height: $field-height;
  background-color: $field-color-ligth;
  border-radius: $field-border-radius;
  border: 1px solid $field-color-ligth;
  transition: 0.3s;
  overflow: hidden;
  .input-wrapper__label {
    position: absolute;
    font-size: 13px;
    left: 16px;
    top: 3px;
    opacity: 0.5;
  }
  &:first-child {
    flex-grow: 1;
    // margin-right: 10px;
  }
}

.react-date-picker {
  .react-calendar {
    border: 1px solid #e5e9ef;
    padding: 12px;
    font-family: $primaryFont;
    border-radius: $field-border-radius;
    // @media screen and (min-width: $desk_md) {
    //   position: fixed;
    // }
    &__navigation {
      margin-bottom: 4px;
      width: 100%;
      &__arrow {
        width: 12%;
        padding: 0;
        font-size: 35px;
        font-weight: 300;
      }
    }
    button {
      color: $second__color_dark;
      min-width: 30px;
    }
    abbr {
      color: $second__color_dark !important;
    }
    &__month-view__weekdays {
      border-bottom: 1px solid $second__color_light;
      border-top: 1px solid $second__color_light;
      &__weekday {
        padding: 6px 10px;
      }
    }

    &__tile {
      padding: 9px 8px;
      border-radius: 5px;
      &:hover {
        background-color: $first__color;
        border-color: $first__color;
        color: white !important;
        abbr {
          color: white !important;
        }
      }

      &:active {
        background-color: $first__color_active;
      }

      &--active,
      &--hasActive {
        background-color: transparent;
        border: 1px solid $first__color;
        &:focus-visible {
          background-color: $second__color_light;
        }
      }
      &--now {
        background-color: $second__color_dark;
        border: 1px solid $second__color_dark;

        color: white !important;
        &:focus-visible {
          background-color: $second__color_dark;
          border-color: $first__color_active;
        }
        abbr {
          color: white !important;
        }
      }
    }
  }
}

.react-time-picker,
.react-date-picker {
  height: 100%;
  width: 100%;
  position: static !important;
  .react-time-picker__inputGroup {
    min-width: 54px;
  }
  // @media screen and (min-width: $desk_md) {
  //   position: relative !important;
  // }
  &__wrapper {
    border: 0px solid $first__color;
    padding: 0 10px;
    padding-top: 10px;
    padding-left: 14px;
  }
  &__inputGroup {
    span {
      display: inline-block;
    }
  }

  &__button {
    padding: 0;
    transform: translateY(-12%);
  }

  &__inputGroup__input {
    caret-color: $first__color;
    &:focus-visible {
      outline: none;
    }
  }

  &__inputGroup {
    // transform: translateY(10%);
  }

  &__inputGroup,
  &__inputGroup__input {
    font-size: 14px;
    color: $color-black;
    font-weight: 700;
    &:invalid {
      background: transparent;
    }
  }

  &--disabled {
    background-color: white;
  }

  &--disabled &__inputGroup__input, &--disabled span {
    color: rgba($color: #000000, $alpha: 0.5) !important;
    &:invalid {
      background: transparent;
    }
  }

  &--open {
    .react-date-picker__calendar-button {
      svg {
        path {
          stroke: $first__color;
        }
      }
    }
    .react-date-picker__clear-button {
      opacity: 1;
    }
  }

  &__clear-button {
    width: 10px;
    margin: 0 2px;
    margin-right: 10px;
    transition: 0.3s;
    opacity: 0.5;
    outline: 0;
    button {
      outline: 0 !important;
    }
    & > * {
      transition: 0.3s;
      width: 100%;
    }
    &:hover {
      opacity: 1;
    }
    &:focus-visible {
      path {
        outline: 1px solid rgba($color: black, $alpha: 0.6);
      }
    }
  }
  &__calendar-button {
    margin: 0 5px;
    width: 17px;
    height: 100%;
    outline: 0;
    button {
      outline: 0;
    }
    & > * {
      transition: 0.3s;
    }
    &:focus-visible {
      svg {
        outline: 1px solid rgba($color: $first__color_active, $alpha: 0.5);
      }
    }
  }
}

.react-date-picker__calendar {
  z-index: 9;
  // left: -20px !important;
  max-width: 80vw !important;
}
