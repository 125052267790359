@import '../../../../../../style/color';
@import '../../../../../../style/breakpoints';

.text-hint {
  position: relative;
  &__type {
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    cursor: pointer;
    &:hover {
      .text-block {
        visibility: visible;
      }
    }
    .text-block {
      visibility: hidden;
      position: absolute;
      bottom: 55px;
      padding: 17px 20px;
      max-width: 300px;
      width: max-content;
      background-color: $field-color-ligth;
      transform: translateX(-68%);
      @media screen and (min-width: $tablet) {
        transform: translateX(-20%);
      }
      &::after {
        content: '';
        position: absolute;
        background-color: $field-color-ligth;
        width: 50px;
        height: 50px;
        bottom: 0px;
        right: 21%;
        transform: rotate(45deg);
        z-index: -1;
        @media screen and (min-width: $tablet) {
          left: 15%;
        }
      }
    }
  }
}
