@import './color';
@import './breakpoints';
@import './var';

a {
  color: $first__color;
  transition: 0.3s;
  border: 1px solid transparent;
  &:hover {
    color: $first__color_hover;
  }
  &:active {
    color: $first__color_active;
  }

  &:focus-visible {
    color: $first__color_active;
    border-color: $first__color;
    border-radius: 8px;
    text-decoration: underline;
    outline: none;
  }

  font-weight: 700;
  font-size: 16px;
  @media screen and (min-wifth: $desk_sm) {
    font-size: 18px;
  }
}

$table-border-radius: 10px;
caption {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  @media screen and (min-width: $desk_sm) {
    font-size: 24px;
    margin-bottom: 30px;
  }
}

p {
  margin: 14px 0;
  word-wrap: break-word;
}

table {
  width: 100%;
  margin: 0 auto;
  border: 0px;
  font-size: $font-size-table;
  border-spacing: 1px;
  border-radius: $table-border-radius;
  background-color: $tooltip-card;
  @media screen and (min-width: $desk_lg) {
    font-size: $font-size-table-desktop;
  }

  tbody {
    & > :first-child {
      td:first-child {
        border-radius: 10px 0 0 0;
      }
      td:last-child {
        border-radius: 0 10px 0 0;
      }
    }
    & > :last-child {
      td:first-child {
        border-radius: 0 0 0 10px;
      }
      td:last-child {
        border-radius: 0 0 10px 0;
      }
    }
  }

  thead {
    th:first-child {
      border-radius: 10px 0 0 0;
    }
    th:last-child {
      border-radius: 0 10px 0 0;
    }

    th {
      background-color: $background-card;
      border: 0px solid $tooltip-card;
      &:last-child {
        border-top-right-radius: 8px;
      }
      &:first-child {
        border-top-left-radius: 8px;
      }
    }

    & + tbody {
      & > :first-child {
        td {
          border-radius: 0 !important;
        }
      }
      & > :last-child {
        td {
          border-radius: 0;
        }
        td:first-child {
          border-radius: 0 0 0 10px !important;
        }
        td:last-child {
          border-radius: 0 0 10px 0 !important;
        }
      }
    }
  }

  td,
  th {
    text-align: center;
    border: 0px solid $tooltip-card;
    padding: 10px 5px;
    @media screen and (min-width: $desk_lg) {
      text-align: left;
      padding: 25px 15px;
    }
  }
  td {
    background-color: white;
  }
}

h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
  @media screen and (min-width: $desk_sm) {
    font-size: 55px;
    line-height: 60px;
  }
}

img {
  margin: 0 auto;
  clear: both;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  @media screen and (min-width: $desk_sm) {
    font-size: 45px;
    line-height: 55px;
  }
}

h3 {
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  @media screen and (min-width: $desk_sm) {
    font-size: 24px;
  }
}

li {
  font-size: 18px;
  font-weight: 400;
  li {
    font-size: 18px;
  }
}

.articleCard {
  blockquote {
    position: relative;
    border-radius: 10px;
    background-color: $field-color-ligth;
    text-align: center;
    padding: 70px 10px;
    font-size: 22px;

    &::after, &::before {
      content: '';
      position: absolute;
      width: 30px;
      height: 50px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='31' height='25' viewBox='0 0 31 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.75001 11.8839C5.06524 11.0314 6.62637 10.6232 8.19791 10.7207C9.76945 10.8182 11.2662 11.4163 12.4623 12.4246C13.6584 13.4329 14.489 14.7969 14.8289 16.3107C15.1688 17.8246 14.9994 19.4061 14.3464 20.8169C13.6935 22.2277 12.5922 23.3912 11.2089 24.1318C9.8255 24.8724 8.23503 25.15 6.67738 24.9228C5.11973 24.6956 3.67938 23.9758 2.57358 22.872C1.46779 21.7682 0.756537 20.3404 0.547112 18.8038C-0.115242 15.6002 -0.372926 11.7499 0.922749 8.26412C2.31279 4.52996 5.3941 1.46758 10.9125 0.0453854C11.3724 -0.0586529 11.8553 0.0177536 12.2589 0.258402C12.6625 0.49905 12.955 0.885015 13.0745 1.33445C13.1939 1.78389 13.1308 2.26146 12.8987 2.66588C12.6665 3.07031 12.2834 3.36979 11.8307 3.50083C7.36665 4.65145 5.27977 6.94912 4.3307 9.49336C4.04943 10.2527 3.85889 11.0549 3.75001 11.8839Z' fill='%239DA6BA'/%3E%3Cpath d='M20.5 11.8839C21.7276 11.0314 23.1846 10.6232 24.6514 10.7207C26.1182 10.8182 27.5151 11.4163 28.6315 12.4246C29.7478 13.4329 30.5231 14.7969 30.8403 16.3107C31.1575 17.8246 30.9995 19.4061 30.39 20.8169C29.7806 22.2277 28.7527 23.3912 27.4616 24.1318C26.1705 24.8724 24.686 25.15 23.2322 24.9228C21.7784 24.6956 20.4341 23.9758 19.402 22.872C18.3699 21.7682 17.7061 20.3404 17.5106 18.8038C16.8924 15.6002 16.6519 11.7499 17.8612 8.26412C19.1586 4.52996 22.0345 1.46758 27.185 0.0453854C27.6142 -0.0586529 28.065 0.0177536 28.4416 0.258402C28.8183 0.49905 29.0914 0.885015 29.2028 1.33445C29.3143 1.78389 29.2555 2.26146 29.0387 2.66588C28.822 3.07031 28.4645 3.36979 28.042 3.50083C23.8755 4.65145 21.9278 6.94912 21.042 9.49336C20.7795 10.2527 20.6016 11.0549 20.5 11.8839Z' fill='%239DA6BA'/%3E%3C/svg%3E%0A");
    }

    &::after, &::before{
      left: 50%;
    }

    &::before{
      transform: translateX(-50%);
      top: 8%;
      @media screen and (min-width: $desk_sm) {
        left: 10%;
        top: 17%;
      }
    }

    &::after{
      transform: translateX(-50%) rotate(180deg);
      bottom: 8%;
      @media screen and (min-width: $desk_sm) {
        left: auto;
        bottom: 30%;
        right: 9%;
      }
    }

    @media screen and (min-width: $desk_sm) {
      padding: 60px 15%;
    }
  }
}

blockquote {
  margin: 10px 0;
  font-style: italic;
  font-size: 22px;
  line-height: 28.6px;
  @media screen and (min-width: $desk_sm) {
    font-size: 32px;
    line-height: 41.6px;
  }
  & * {
    font-size: inherit !important;
    line-height: inherit !important;
  }
}

p {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  @media screen and (min-width: $desk_sm) {
    font-size: 18px;
  }
}
